<template>
    <form-field-wrapper
        :label="label"
        :help="help"
        :error="error"
        :class="['textarea-field' + addedClasses]"
        :optional="optional"
        :label-for="fieldId"
    >
        <textarea
            :id="fieldId"
            class="form-textarea"
            v-model="fieldValue"
            :placeholder="placeholder"
            :rows="rows"
            @change="$emit('change', value)"
        />
    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';
export default {
    extends: FormFieldBase,
    name: 'textarea-field',
    props: {
        config: {
            type: Object,
            default: () => {
                return {
                    rows: 3
                }
            }
        }
    },
    components: { FormFieldWrapper },
    computed: {
        rows() {
            if (this.config && this.config.hasOwnProperty('rows')) {
                return this.config.rows;
            }
            return 3;
        },
    },
}
</script>
