<template>
<!-- This is the wrapper for all form fields. The field component goes into the slot -->
    <div :class="['form-field', { 'has-error': hasError }]">
        <div class="label-container">
            <label
                v-if="label"
                :class="['field-label', { 'is-clickable': labelClickable }]"
                @click.stop="onLabelClicked"
                :for="labelFor">
                {{ label }} <span v-if="optional" class="text-xs font-medium text-gray-400">(Optional)</span>
            </label>
            <slot name="additionalContent"></slot>
        </div>
        <slot></slot>
        <span v-if="help" class="help block mt-1 mb-2 text-sm text-grey-dark" v-html="help"></span>
        <span v-if="error" class="error block mt-1 mb-2 text-sm text-red">{{ error }}</span>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String
        },
        help: {
            type: String,
        },
        optional: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
        },
        labelClickCallback: {
            type: Function
        },
        labelFor: {
            type: String
        }
    },
    components: { },
    data() {
        return {
        };
    },
    computed: {
        hasError() {
            return this.error !== null && this.error !== undefined && this.error.length;
        },
        labelClickable() {
            return this.labelClickCallback !== null && this.labelClickCallback !== undefined;
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
        onLabelClicked() {
            if (this.labelClickCallback) {
                this.labelClickCallback();
            }
        }
    }
}
</script>
<style lang="scss">
.label-container {
    margin-left: 2px;
}
.help, .error {
    margin-left: 2px;
    width: 100%;
}
.is-clickable {
    pointer-events: all;
    user-select: none;
    cursor: pointer;
}
</style>
