<template>
    <form-field-wrapper
        :label="label"
        :label-for="fieldId"
        :help="help"
        :optional="optional"
        :error="error"
        :class="['input-field' + addedClasses]"
    >
        <input
            :id="fieldId"
            v-model="fieldValue"
            class="form-input"
            :placeholder="placeholder"
            :readonly="readOnly"
            :type="fieldType"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @change="$emit('change', value)"
        />
    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';
export default {
    extends: FormFieldBase,
    name: 'input-field',
    props: {
        config: {
            type: Object,
            default: () => {}
        }
    },
    components: { FormFieldWrapper },
    computed: {
        fieldType() {
            if (this.type) {
                return this.type;
            }
            return 'text';
        },
        readOnly() {
            if(this.config && this.config.readOnly == true){
                return true;
            }else{
                return false;
            }
        }
    },
}
</script>
