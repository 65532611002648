<template>
    <button
        v-if="hasSearch"
        class="absolute text-red-darker transform -translate-x-8"
        title="Clear Search"
        type="button"
        @click="clearSearch"
    >
        <i class="fa fa-times fa-lg"></i>
    </button>
</template>

<script>
export default {
    name: "vgt-clear-button",
    computed: {
        table() {
            return this.$parent && this.$parent.$parent;
        },
        hasSearch() {
            return this.table && this.table.globalSearchTerm
        },
    },
    methods: {
        clearSearch() {
            if (!this.table) {
                return;
            }

            this.table.globalSearchTerm = '';
            this.table.searchTableOnEnter();
        },
    },
}
</script>
