<template>
    <form-field-wrapper
        :label="label"
        :label-for="fieldId"
        :help="help"
        :optional="optional"
        :error="error"
        :class="['input-field' + addedClasses]"
    >
        <vue-tel-input
            :id="fieldId"
            v-model="fieldValue"
            class="form-input"
            :placeholder="placeholder"
            :readonly="readOnly"
            :autoFormat="true"
            defaultCountry="US"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @input="onValueChanged"
            @validate="onValidated"
        ></vue-tel-input>
        <p v-if="pinMessage">
            {{ pinMessage }}
        </p>
        <button
            v-if="showPinReset && fieldValue && !numberChanged"
            :disabled="!numberValid"
            class="btn btn-default float-right mt-1"
            @click.stop.prevent="onResetClicked"
        >
            Re-set Pin
        </button>

        <button
            v-if="showPinReset && fieldValue && numberChanged"
            :disabled="!numberValid"
            class="btn btn-default float-right mt-1"
            @click.stop.prevent="onSaveClicked"
        >
            Update Number & Re-set Pin
        </button>

    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';

const DEFAULT_CONFIG = {
    saveCallback: null,
    resetCallback: null,
    showPinReset: false,
    pinMessage: null,
};

export default {
    extends: FormFieldBase,
    name: 'phone-input-field',
    props: {
        saveCallback: {
            type: Function
        },
        resetCallback: {
            type: Function
        },
        config: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            defaultValue: null,
            numberChanged: false,
            numberValid: false,
        };
    },
    mounted() {
        this.defaultValue = null;
        this.numberChanged = false;
        this.numberValid = false;
    },
    components: { FormFieldWrapper },
    computed: {
        fieldType() {
            if (this.type) {
                return this.type;
            }
            return 'text';
        },
        readOnly() {
            if(this.config && this.config.readOnly == true){
                return true;
            }else{
                return false;
            }
        },
        showPinReset() {
            if(this.config && this.config.showPinReset == true){
                return true;
            }else{
                return false;
            }
        },
        pinMessage() {
            if(this.config && this.config.pinMessage){
                return this.config.pinMessage
            }else{
                return null;
            }
        },
    },

    methods: {
        onValueChanged(number, phoneObject) {
            if(phoneObject.isValid){
                if(this.defaultValue  != phoneObject.number.international){
                    this.numberChanged = true;
                }else{
                    this.numberChanged = false;
                }

                this.fieldValue = phoneObject.number.international;

                if ( this.getConfigProperty('numberChangedCallback') ) {
                    this.getConfigProperty('numberChangedCallback')(this.numberChanged);
                }
            }
        },
        onValidated(phoneObject) {
            this.numberValid = phoneObject.isValid;
            if(!this.defaultValue && phoneObject.isValid){
                this.defaultValue = phoneObject.number.international;
            }
            if ( this.getConfigProperty('validationCallback') ) {
                this.getConfigProperty('validationCallback')(this.numberValid);
            }
        },
        onSaveClicked(evt) {
            if ( this.getConfigProperty('saveCallback') ) {
                this.getConfigProperty('saveCallback')(this.fieldValue);
            }
        },
        onResetClicked(evt) {
            if ( this.getConfigProperty('resetCallback') ) {
                this.getConfigProperty('resetCallback')(this.fieldValue);
            }
        },
        getConfigProperty(property) {
            if (this.config && this.config.hasOwnProperty(property)) {
                return this.config[property];
            }
            return DEFAULT_CONFIG[property];
        }
    },
}
</script>
