<script>
import VgtClearButton from '@/components/vgt-clear-button'

export default {
    name: 'portal-table',
    functional: true,
    render(h, context) {
        const data = context.data;
        const slots = context.slots();

        // If the table has search enabled, add the clear search button.
        if (data.attrs['search-options'] && data.attrs['search-options'].enabled) {
            const children = slots?.['table-actions']?.[0]?.children || [];

            slots['table-actions'] = [{
                tag: 'div',
                data: {staticClass: 'flex items-center'},
                children: [
                    h(VgtClearButton),
                    ...children
                ],
            }];
        }

        return h('vue-good-table', {
            ...data,
       }, context.children);
    },
};
</script>
