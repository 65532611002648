<template>
    <div class="flex flex-wrap p-6 my-6 box max-w-4xl">
        <div class="min-w-64 mr-10">
            <info-property-value
                v-if="fields"
                v-for="field of fields"
                :key="field.name"
                :property="field.name"
                :value="field.value"
                >
            </info-property-value>
            <info-property-value v-if="!fields && leftLabel" :property="leftLabel"></info-property-value>
            <slot v-if="!fields" name="left"></slot>
        </div>
        <div class="flex-grow flex flex-col">
            <info-property-value v-if="rightLabel" :property="rightLabel"></info-property-value>
            <slot name="right"></slot>
        </div>
        <div class="w-full flex justify-end mt-6">
            <slot name="actions"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'info-detail',
    props: {
        fields: {
            type: Array
        },
        rightLabel: {
            type: String
        },
        leftLabel: {
            type: String
        }
    },
    components: {},
    data() {
        return {
        };
    },
    computed: {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
</style>
