export default {
    computed: {
        authUser() {
            return this.$page?.props?.auth?.user || {};
        },
        authRoles() {
            return this.authUser?.role || [];
        },
        authPermissions() {
            return this.authUser?.permissions || [];
        },
    },
    methods: {
        hasRole(roles) {
            if (!Array.isArray(roles)) {
                roles = [roles];
            }

            return roles.some(role => this.authRoles.includes(role));
        },
        can(permission) {
            return this.authPermissions.includes(permission);
        },
    }
}
