<template>
    <form-field-wrapper
        :label="label"
        :help="help"
        :error="error"
        :class="['radio-field' + addedClasses, { 'display-inline': inline }]"
        :optional="optional"
        >
        <div class="options-container">
            <div
                class="flex items-center my-2 ml-2"
                v-for="(radioOption, index) of options"
                :key="radioOption.id"
                >
                <input
                    class="form-radio h-4 w-4 text-primary-lighter transition duration-150 ease-in-out"
                    v-model="fieldValue"
                    :id="getOptionId(index)"
                    :name="label"
                    :value="radioOption.id"
                    type="radio"
                    />
                    <label :for="getOptionId(index)" class="ml-3">
                        <span class="block text-base leading-5 font-medium">{{ radioOption.label }}</span>
                    </label>
            </div>
        </div>
    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';
export default {
    extends: FormFieldBase,
    name: 'radio-field',
    props: {
        config: {
            type: Object,
            default: () => {
                return {
                    inline: false, // Options are all in a row
                };
            }
        }
    },
    components: { FormFieldWrapper },
    computed: {
        inline() {
            if (this.config && this.config.hasOwnProperty('inline')) {
                return this.config.inline;
            }
            return false;
        },
    },
}
</script>
