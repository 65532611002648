<template>
    <form-field-wrapper
        :label="label"
        :label-for="fieldId"
        :help="help"
        :error="error"
        :class="['select-field' + addedClasses]"
        :optional="optional"
        >
        <multiselect
            class=""
            :id="fieldId"
            v-model="fieldValue"
            :options="selectOptions"
            :placeholder="placeholder"
            :showLabels="false"
            :trackBy="valueProperty"
            :label="displayProperty"
            :multiple="multiple"
            :allowEmpty="allowEmpty"
            :searchable="searchable"
            :closeOnSelect="closeOnSelect"
        >
            <template slot="option" slot-scope="props">
                <slot name="option" v-bind:option="props.option"></slot>
            </template>
            <span slot="noOptions" class="no-options-text">{{ noOptionsText }}</span>
        </multiselect>
    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';

const DEFAULT_CONFIG = {
    valueProperty: 'id',
    displayProperty: 'label',
    allowEmpty: true,
    noOptionsText: "List is empty",
    useObjectForValue: false,
    multiple: false,
};

export default {
    extends: FormFieldBase,
    name: 'select-field',
    props: {
        config: {
            type: Object,
            default: () => {
                return DEFAULT_CONFIG;
            }
        }
    },
    components: { FormFieldWrapper },
    computed: {
        selectOptions() {
            // If the options array is a simple string array, make each value a complex object
            if (this.options && this.options.length && (typeof this.options[0]) === 'string') {
                let returnOptions = this.options.map(option => {
                    return {
                        id: option,
                        label: option
                    };
                });
                return returnOptions;
            }
            return this.options;
        },
        fieldValue: {
            get() {
                if (!this.value) {
                    return undefined;
                }
                if (this.multiple) {
                    let selectedOptions = [];
                    for (let selection of this.value) {
                        if (!selection) {
                            continue;
                        }
                        let foundValue;
                        if (!this.valueIsObject) {
                            foundValue = this.selectOptions.find(option => option[this.valueProperty] === selection);
                        } else {
                            foundValue = this.selectOptions.find(option => option[this.valueProperty] === selection[this.valueProperty]);
                        }
                        if (foundValue) {
                            selectedOptions.push(foundValue);
                        } else {
                        }
                    }
                    return selectedOptions;
                } else {
                    if (!this.valueIsObject) {
                        return this.selectOptions.find(option => option[this.valueProperty] === this.value);
                    } else {
                        return this.selectOptions.find(option => option[this.valueProperty] === this.value[this.valueProperty]);
                    }
                }
            },
            set(value) {
                if (this.multiple) {
                    this.$emit('input', this.valueIsObject ? [ ...value ] : value.map(v => v[this.valueProperty]));
                } else {
                    this.$emit('input', this.valueIsObject ? { ...value } : value[this.valueProperty]);
                }
            }
        },
        valueIsObject() {
            return this.getConfigProperty('useObjectForValue');
        },
        valueProperty() {
            return this.getConfigProperty('valueProperty');
        },
        displayProperty() {
            return this.getConfigProperty('displayProperty');
        },
        multiple() {
            return this.getConfigProperty('multiple');
        },
        allowEmpty() {
            return this.getConfigProperty('allowEmpty');
        },
        noOptionsText() {
            return this.getConfigProperty('noOptionsText');
        },
        searchable() {
            if (this.config && this.config.hasOwnProperty('searchable')) {
                return this.config.searchable;
            }
            if (!this.multiple && this.selectOptions.length < 5) {
                return false;
            }
            return true;
        },
        closeOnSelect() {
            if (this.config && this.config.hasOwnProperty('closeOnSelect')) {
                return this.config.closeOnSelect;
            }

            return true;
        }
    },
    created() {
    },
    mounted() {
        this.$nextTick(() => {
            if (!this.allowEmpty && !this.fieldValue) {
                this.fieldValue = this.selectOptions[0];
            }
        });
    },
    methods: {
        getConfigProperty(property) {
            if (this.config && this.config.hasOwnProperty(property)) {
                return this.config[property];
            }
            return DEFAULT_CONFIG[property];
        }
    }
}
</script>
<style lang="scss" scoped>
    .multiselect {
        width: 100% !important;
    }
    .no-options-text {
        opacity: 0.5;
    }
</style>
