<template>
    <form-field-wrapper
        :label="label"
        :help="help"
        :error="error"
        :class="['checkbox-options-field' + addedClasses, { 'display-inline': inline }]"
        :optional="optional"
        >
        <div class="options-container">
            <div
                class="flex items-center my-2 ml-2"
                v-for="(checkOption, index) of checkOptions"
                :key="checkOption.id"
                >
                <input
                    class="form-checkbox h-4 w-4 text-primary-lighter transition duration-150 ease-in-out"
                    v-model="checkOption.checked"
                    :id="getOptionId(index)"
                    :name="label"
                    :value="checkOption.id"
                    type="checkbox"
                    @change="onChanged"
                    />
                    <label :for="getOptionId(index)" class="ml-3">
                        <span class="block text-base leading-5 font-medium">{{ checkOption.label }}</span>
                    </label>
            </div>
        </div>
    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';
export default {
    extends: FormFieldBase,
    name: 'checkbox-options-field',
    props: {
        config: {
            type: Object,
            default: () => {
                return {
                    inline: false, // Options are all in a row
                };
            }
        }
    },
    components: { FormFieldWrapper },
    data() {
        return {
            checkOptions: [],
        };
    },
    computed: {
        inline() {
            if (this.config && this.config.hasOwnProperty('inline')) {
                return this.config.inline;
            }
            return false;
        },
    },
    mounted() {
        this.updateCheckOptions();
    },
    methods: {
        onChanged(event) {
            this.$nextTick(() => {
                this.setValue();
            });
        },
        setValue() {
            let value = [];
            for (let i = 0; i < this.checkOptions.length; i++) {
                if (this.checkOptions[i].checked) {
                    value.push(this.checkOptions[i].id);
                }
            }
            this.fieldValue = value;
        },
        updateCheckOptions() {
            let returnOpts = [];
            for (let selectOption of this.options) {
                selectOption = { ...selectOption };
                selectOption.checked = this.fieldValue !== undefined && this.fieldValue !== null && this.fieldValue.length > 0 && this.fieldValue.indexOf(selectOption.id) !== -1;
                returnOpts.push(selectOption);
            }
            this.checkOptions = returnOpts;
        },
    }
}
</script>
