
require('./bootstrap');

import { InertiaApp, plugin } from '@inertiajs/inertia-vue'
//import { plugin } from '@inertiajs/inertia-vue'
import authMixin from '@/shared/mixins/auth-mixins';
import Vue from 'vue';

import VueGoodTablePlugin from 'vue-good-table';
import PortalTable from '@/components/portal-table';

import vmodal from 'vue-js-modal';

import Multiselect from 'vue-multiselect'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import Goose from 'goose';

import VueTelInput from 'vue-tel-input'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';


// import the styles
import 'vue-good-table/dist/vue-good-table.css';
import ProgressBar from 'vue-progressbar-component';
import PortalForm from './components/ui/portal-form';
import InputField from './components/ui/form-fields/input-field';
import RichTextField from './components/ui/form-fields/rich-text-field';
import TextAreaField from './components/ui/form-fields/textarea-field';
import DateField from './components/ui/form-fields/date-field';
import SelectField from './components/ui/form-fields/select-field';
import RadioField from './components/ui/form-fields/radio-field';
import CheckboxField from './components/ui/form-fields/checkbox-field';
import CheckboxOptionsField from './components/ui/form-fields/checkbox-options-field';
import InfoDetail from './components/ui/info-detail';
import InfoPropertyValue from './components/ui/info-property-value';
import PhoneInputField from './components/ui/form-fields/phone-input-field';
import { InertiaProgress } from '@inertiajs/progress'
import Datepicker from 'vuejs-datepicker';


InertiaProgress.init({
    // The delay after which the progress bar will
    // appear during navigation, in milliseconds.
    delay: 250,

    // The color of the progress bar.
    color: 'green',

    // Whether to include the default NProgress styles.
    includeCSS: true,

    // Whether the NProgress spinner will be shown.
    showSpinner: false,
  })
// Vue.use(plugin);
Vue.use(VueTelInput)
Vue.use(Goose);

Vue.component('progress-bar', ProgressBar);
Vue.component('multiselect', Multiselect);
Vue.component(PortalForm.name, PortalForm);
Vue.component(InputField.name, InputField);
Vue.component(RichTextField.name, RichTextField);
Vue.component(TextAreaField.name, TextAreaField);
Vue.component(DateField.name, DateField);
Vue.component(SelectField.name, SelectField);
Vue.component(RadioField.name, RadioField);
Vue.component(CheckboxField.name, CheckboxField);
Vue.component(CheckboxOptionsField.name, CheckboxOptionsField);
Vue.component(InfoDetail.name, InfoDetail);
Vue.component(InfoPropertyValue.name, InfoPropertyValue);
Vue.component(PhoneInputField.name, PhoneInputField);
Vue.use(VueGoodTablePlugin);
Vue.component(PortalTable.name, PortalTable);
Vue.use(Antd);
Vue.use(vmodal, {dialog: true} );
Vue.mixin({ methods: { route: window.route } });
Vue.mixin(authMixin);
Vue.use(plugin);
Vue.use(IconsPlugin)
Vue.use(BootstrapVue)
Vue.use(Datepicker);



// Used for clicking outside popup windows
window.outsideClickHandlers = {};
Vue.directive('click-outside', {
    bind (el, binding, vnode) {

        if (!binding.value.id) {
            throw new Error('You must have a unique id for this v-click-outside directive');
        }

        // Here's the click/touchstart handler
        // (it is registered below)
        let handler = (e) => {
            e.stopPropagation();
            // Get the handler method name and the exclude array
            // from the object used in v-closable
            const { handler, exclude } = binding.value;

            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false;

            // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
            if (exclude) {
                exclude.forEach(refName => {
                    // We only run this code if we haven't detected
                    // any excluded element yet
                    if (!clickedOnExcludedEl) {
                        // Get the element using the reference name
                        const excludedEl = vnode.context.$refs[refName];
                        // See if this excluded element
                        // is the same element the user just clicked on
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                });
            }

            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the dialog
                // and not the button, then call the outside-click handler
                // from the same component this directive is used in
                vnode.context[handler]();
            }
        };

        outsideClickHandlers[vnode.context._uid + '_' + binding.value.id] = handler;

        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handler);
        document.addEventListener('touchstart', handler);
    },

    unbind (el, binding, vnode) {
        let handler = outsideClickHandlers[vnode.context._uid + '_' + binding.value.id];
        if (handler) {
            // If the element that has v-closable is removed, then
            // unbind click/touchstart listeners from the whole page
            document.removeEventListener('click', handler);
            document.removeEventListener('touchstart', handler);
        }
    }
});

/*
// Used for clicking outside popup windows
let handleOutsideClick;
Vue.directive('click-outside', {
    bind (el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        handleOutsideClick = (e) => {
            e.stopPropagation();
            // Get the handler method name and the exclude array
            // from the object used in v-closable
            const { handler, exclude } = binding.value;

            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false;

            // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
            if (exclude) {
                exclude.forEach(refName => {
                    // We only run this code if we haven't detected
                    // any excluded element yet
                    if (!clickedOnExcludedEl) {
                        // Get the element using the reference name
                        const excludedEl = vnode.context.$refs[refName];
                        // See if this excluded element
                        // is the same element the user just clicked on
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                });
            }

            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the dialog
                // and not the button, then call the outside-click handler
                // from the same component this directive is used in
                vnode.context[handler]();
            }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },

    unbind () {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    }
});
*/

const app = document.getElementById('app')

new Vue({
    render: h => h(InertiaApp, {
        props: {
            initialPage: JSON.parse(app.dataset.page),
            resolveComponent: name => import(`@/pages/${name}`).then(module => module.default),
        },
    }),
}).$mount(app)

