<template>
    <div
        :class="hasValue ? 'mb-7' : ''"
        >
        <h3 v-if="property" :class="'text-primary uppercase mb-1 ' + propertyClass">{{ property }}</h3>
        <p v-if="hasValue" :class="'text-xl text-grey-darker ' + valueClass">
            {{ value }}
            <slot name="value"></slot>
        </p>
    </div>
</template>
<script>
export default {
    name: 'info-property-value',
    props: {
        property: {
            type: String
        },
        value: {
        },
        propertyClass: {
            type: String,
            default: ''
        },
        valueClass: {
            type: String,
            default: ''
        }
    },
    components: {},
    data() {
        return {
        };
    },
    computed: {
        hasValue() {
            return this.value !== undefined && this.value !== null;
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
</style>