<template>
    <form-field-wrapper
        :label="label"
        :label-for="fieldId"
        :help="help"
        :error="error"
        :class="['date-field' + addedClasses]"
        :optional="optional"
        >
        <input
            :id="fieldId"
            class="form-input"
            v-model="fieldValue"
            type="date"
            />
    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';
export default {
    name: 'date-field',
    extends: FormFieldBase,
    components: { FormFieldWrapper },
    props: {
        config: {
            type: Object,
            default: () => {}
        }
    },
}
</script>
