<template>
    <form class="portal-form flex content-start flex-wrap -m-6 px-4 py-6" @submit.prevent="submitCallback">
        <slot name="top-content"></slot>
        <div
            v-for="field of fields"
            :key="field.id"
            :class="['w-full flex px-0 sm:px-4 py-2', {
                'md:w-1/2': columns === 2,
                'md:w-1/3': columns === 3,
            }]"
            :style="field.customStyle"
            >
            <component
                v-bind:is="field.component"
                :label="field.label"
                :value="formData[field.id]"
                @input="onValueChanged(field.id, $event)"
                :type="field.type"
                :options="field.options"
                :placeholder="field.placeholder !== null && field.placeholder !== undefined
                    ? field.placeholder : (autoPlaceholders ? field.label : null)"
                :help="field.help"
                :error="getFieldError(field.id)"
                :config="field.config"
                :optional="field.optional"
                >
            </component>
        </div>
        <slot name="additional-content"></slot>
        <div class="form-actions-container">
            <div class="left-side"><slot name="left"></slot></div>
            <div class="right-side"><slot name="right"></slot></div>
        </div>
    </form>
</template>
<script>
export default {
    name: 'portal-form',
    props: {
        value: {
            type: Object,
                default: () => {
                return {};
            }
        },
        errors: {
            type: Object,
        },
        submitCallback: {
            type: Function,
            default: () => {
                console.error('There is no submit callback for this form!');
            }
        },
        columns: {
            type: Number,
            default: 1
        },
        autoPlaceholders: {
            type: Boolean,
            default: true
        },
        fields: {
            type: Array,
            default: () => {
                return [
                {
                    id: 'inputField',
                    label: 'String Input Field',
                    component: 'input-field',
                },
                {
                    id: 'numberField',
                    label: 'Number Input Field',
                    component: 'input-field',
                    type: 'number',
                },
                {
                    id: 'textarea',
                    label: 'Text Area',
                    component: 'textarea-field',
                    customStyle: { width: '100%' },
                    config: {
                        rows: 4,
                        class: 'w-full' // Additional classes can be applied to any field this way
                    }
                },
                {
                    id: 'dateField',
                    label: 'Date Field',
                    component: 'date-field',
                },
                {
                    id: 'select',
                    label: 'Select Field',
                    component: 'select-field',
                    options: ['Option 1', 'Option 2'],
                    placeholder: 'Select an Option...',
                    help: 'Can be an simple array of strings or objects with this structure: { id, label }',
                    config: {
                        useObjectForValue: false,    // Not sure we should do this, but passes/uses the whole object
                        valueProperty: 'id',         // The property used to track the selections
                        displayProperty: 'name',     // The displayed value in the drop down
                        multiple: true,              // Whether it's a single select or multi-select
                        // This is an example of a custom error handler (i.e. error key is different than field id)
                        validate: (errors, dataObject) => {
                            return errors['custom_error_key'];
                        }
                    }
                },
                {
                    id: 'radio',
                    label: 'Radio Field',
                    component: 'radio-field',
                    options: [
                        {
                            id: 'option1',
                            label: 'Option 1'
                        },
                        {
                            id: 'option2',
                            label: 'Option 2'
                        }
                    ],
                    help: `Available Options - config: {<br/>
                        &nbsp;&nbsp;inline: Boolean (Options in a row, Default = false)<br/>
                    }`,
                },
                {
                    id: 'checkoptions',
                    label: 'Check Options Field',
                    component: 'checkbox-options-field',
                    options: [
                        {
                            id: 'option1',
                            label: 'Option 1'
                        },
                        {
                            id: 'option2',
                            label: 'Option 2'
                        }
                    ],
                    help: `Available Options - config: {<br/>
                        &nbsp;&nbsp;inline: Boolean (Options in a row, Default = false)<br/>
                    }`,
                },
                {
                    id: 'check',
                    label: 'Checkbox',
                    component: 'checkbox-field',
                    help: `Available Options - config: {<br/>
                        &nbsp;&nbsp;inline: Boolean (Checkbox is inline with label. Default = true),<br/>
                        &nbsp;&nbsp;checkLeft: Boolean (Checkbox is to the left of label when inline, Default = false),<br/>
                        &nbsp;&nbsp;content: Boolean (Additional content to appear under label),<br/>
                    }`,
                },
                {
                    id: 'rich-text-field',
                    label: 'Rich Text Field',
                    component: 'rich-text-field',
                },
                ]
            }
        }
    },
    data() {
        return {
            // Custom validation handlers can be passed in the field config. a method that takes the errors as an argument.
            customValidation: {}
        };
    },
    computed: {
        formData: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', { ...value });
            }
        },
    },
    watch: {
        errors: {
            handler() {
                console.log('errors', this.errors);
            },
            deep: true
        }
    },
    mounted() {
        for (let field of this.fields) {
            if (field.config && field.config.validate) {
                this.customValidation[field.id] = field.config.validate;
            }
        }
    },
    methods: {
        getFieldError(fieldId) {
            if (this.errors && this.customValidation[fieldId]) {
                // Pass the errors to the field's custom validation handler
                return this.cleanErrors(this.customValidation[fieldId](this.errors, this.formData));
            }
            return this.cleanErrors(this.errors[fieldId]);
        },
        cleanErrors(error) {
            if (error && error instanceof Array) {
                return error ? error.join(', ') : null;
            }
            return error
        },
        onValueChanged(fieldId, value) {
            this.formData = { ...this.formData, ...{ [fieldId]: value }};
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
