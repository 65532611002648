<template>
    <form-field-wrapper
        :label="label"
        :help="help"
        :error="error"
        :label-for="inputId"
        :class="[
            'checkbox-field' + addedClasses,
            { 'display-inline': inline, 'check-left': checkLeft, 'has-content': content !== null, 'deselected': deselectedStyle }
        ]"
        :optional="optional"
        >
        <input
            class="form-checkbox h-4 w-4 text-primary-lighter transition duration-150 ease-in-out"
            v-model="fieldValue"
            type="checkbox"
            :id="inputId"
            @change="$emit('change', $event.target.checked)"
        />
        <p v-if="content" class="additional-content" slot="additionalContent">
            {{ content }}
        </p>
    </form-field-wrapper>
</template>
<script>
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';
export default {
    name: 'checkbox-field',
    extends: FormFieldBase,
    components: { FormFieldWrapper },
    props: {
        config: {
            type: Object,
            default: () => {
                return {
                    inline: true, // Checkbox is below label or inline with it.
                    checkLeft: false, // Whether checkbox is on the left or right, when inline
                    content: null, // Additional content to appear under the label
                    deselectedLabelStyle: false,
                };
            }
        }
    },
    computed: {
        content() {
            if (this.config && this.config.hasOwnProperty('content')) {
                return this.config.content;
            }
            return null;
        },
        inline() {
            if (this.config && this.config.hasOwnProperty('inline')) {
                return this.config.inline;
            }
            return false;
        },
        checkLeft() {
            if (this.config && this.config.hasOwnProperty('checkLeft')) {
                return this.config.checkLeft;
            }
            return false;
        },
        deselectedLabelStyle() {
            if (this.config && this.config.hasOwnProperty('deselectedLabelStyle')) {
                return this.config.deselectedLabelStyle;
            }
            return false;
        },
        inputId() {
            return this._uid.toString();
        },
        deselectedStyle() {
            return this.deselectedLabelStyle && !this.fieldValue;
        }
    },
    methods: {
    }
}
</script>
<style lang="scss">
.checkbox-field {
    &.deselected {
        label {
            opacity: 0.75;
        }
    }
}
</style>
