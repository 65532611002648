<template>
    <form-field-wrapper
        :label="label"
        :label-for="fieldId"
        :help="help"
        :error="error"
        :class="['rich-text-field' + addedClasses]"
        :optional="optional"
    >
        <vue-editor
            :id="fieldId"
            v-model="fieldValue"
            :editor-toolbar="toolbar"
            useCustomImageHandler
            @image-added="handleImageAdded"
        />
    </form-field-wrapper>
</template>

<script>
import Compressor from 'compressorjs'
import {VueEditor, Quill} from 'vue2-editor';
import FormFieldWrapper from './common/form-field-wrapper';
import FormFieldBase from './common/form-field-base';

const Link = Quill.import('formats/link');

class MyLink extends Link {
    static create(value) {
        const node = super.create(value);
        value = this.sanitize(value);
        node.setAttribute('href', value);
        node.removeAttribute('target');

        return node;
    }
}

Quill.debug('error');
Quill.register(MyLink);

export default {
    extends: FormFieldBase,
    name: 'RichTextField',
    components: {FormFieldWrapper, VueEditor},
    props: {
        config: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote'],

                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent

                [{'header': [1, 2, 3, 4, 5, 6, false]}],

                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'font': []}],
                [{'align': []}],

                ['clean'],

                ['link', 'image', 'video'],
            ],

        }
    },
    methods: {
        getConfigProperty(property) {
            if (this.config && this.config.hasOwnProperty(property)) {
                return this.config[property];
            }
        },
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            function blobToBase64(blob) {
                const reader = new FileReader();
                reader.readAsDataURL(blob);

                return new Promise(resolve => {
                    reader.onloadend = () => {
                        resolve(reader.result);
                    }
                })
            }

            new Compressor(file, {
                quality: 0.6,
                maxWidth: 1600,
                success(result) {
                    blobToBase64(result).then((data) => {
                        Editor.insertEmbed(cursorLocation, 'image', data);
                        resetUploader();
                    })
                },
                error(err) {
                    console.log(err.message);
                },
            });
        },
    }
}
</script>

<style scoped>
.rich-text-field >>> iframe.ql-video {
    @apply w-full mx-auto px-4;
    height: 56.25vw;
    max-height: calc(800px / 16 * 9);
}
</style>
