<script>

function generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export default {
    name: 'form-field-base',
    props: {
        label: {
            type: String
        },
        value: {
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String
        },
        help: {
            type: String
        },
        optional: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String
        },
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        fieldId: {
            type: String,
            default: () => {
                return generateUuid();
            },
        },
    },
    computed: {
        fieldValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        addedClasses() {
            if (this.config && this.config.hasOwnProperty('class')) {
                return ' ' + this.config.class;
            }

            return '';
        },
    },
    methods: {
        // Keeps option ids unique to this component instance
        getOptionId(index) {
            if (this.options) {
                return this._uid + this.options[index].id;
            }
            return undefined;
        },
    },
}
</script>
